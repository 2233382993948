import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { SharedService } from '../../shared/services/shared/shared.service';
import { CreditCardService } from '../../modules/credit-card/credit-card.service';

@Injectable({ providedIn: 'root' })
export class InterceptorInterceptor implements HttpInterceptor {
  private sharedService = inject(SharedService);
  private creditCardService = inject(CreditCardService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const authHeader =
      request.headers.get('auth') === 'true'
        ? this.creditCardService.creditCardPaymentStateSignal().accessToken ||
          ''
        : '';

    const headers: { [name: string]: string | string[] } = {
      Accept: 'application/json',
      'api-request-from': 'web',
      Authorization: authHeader ? `Bearer ${authHeader}` : '',
    };

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      finalize(() => {
        this.sharedService.loading.next(false);
      }),
    );
  }
}
